<!--
* @FileDescription: 用户管理
* @Author: chenz
* @Date: 2021/4/28
* @LastEditors: 最后更新作者
* @LastEditTime: 最后更新时间
-->
<template>
  <div class="user-manage">
    <!-- 查询表单 -->
    <CommonQueryFrom
      :queryForm="queryForm"
      :queryFormItemList="queryFormItemList"
      @_add="_add"
      @_query="_query"
      @_export="_export"
    ></CommonQueryFrom>
    <!-- 表格 -->
    <CommonTable
      :tableData="tableData"
      :columns="columns"
      :loading="loading"
      :pagingOptions="pagingOptions"
      @_del="_del"
      @_edit="_edit"
      @_sizeChange="_sizeChange"
      @_currentChange="_currentChange"
      @_handleSelectionChange="_handleSelectionChange"
    >
    </CommonTable>
    <!-- 弹框表单 -->
    <CommonDialogForm
      ref="form"
      :form="form"
      :rules="rules"
      :visible="visible"
      :title="dialogTitle"
      :formItemList="formItemList"
      @_Close="_Close"
      @_cancel="_cancel"
      @_confirm="_confirm"
      @_cascaderChange="_cascaderChange(arguments)"
    >
    </CommonDialogForm>
  </div>
</template>

<script>
// 引入侧边栏json
const formItemList = require("./formItemList")

// 引入方法
import {
  getExcelLabelOrProp,
  getSex,
  getPhoneValidator,
  getIDCardValidator,
} from "../../utils/tool"

// 引入接口
import { getUserList, createUser, delUser, updateUser } from "../../api/user"

export default {
  name: "UserManage",

  data() {
    return {
      // 查询表单
      queryForm: {
        phone: "",
        realName: "",
      },

      queryFormItemList: [
        { type: "input", prop: "phone", placeholder: "请输入要查询的手机号" },
        { type: "input", prop: "realName", placeholder: "请输入要查询的姓名" },
      ],

      // 列接受的参数
      columns: [
        // { label: "昵称", prop: "nickname", width: 120 },
        { label: "真实姓名", prop: "realName", width: 120 },
        { label: "生日", prop: "birthday", width: 120 },
        { label: "性别", prop: "sex", width: 100 },
        { label: "手机号码", prop: "phone", width: 200 },
        { label: "地址", prop: "address", showOverFlowTooltip: true },
        { label: "身份证号", prop: "idCard", showOverFlowTooltip: true },
        { label: "入会时间", prop: "createTime" },
      ],

      // 表格数据
      tableData: [],

      // 选择的表格数据
      selectChangeTableData: [],

      // 加载
      loading: false,

      // 弹框标题
      dialogTitle: "",

      // 弹框显示
      visible: false,

      // 表单
      form: {
        realName: "",
        birthday: "",
        sex: "",
        phone: "",
        address: "",
        addressCodeList: "",
        // detailAddress: "",
        status: "",
        idCard: "",
        country: "",
      },

      // 校验
      rules: {
        realName: [
          { required: true, message: "真实姓名不能为空", trigger: "change" },
        ],
        birthday: [
          { required: true, message: "生日不能为空", trigger: "change" },
        ],
        sex: [{ required: true, message: "性别不能为空", trigger: "change" }],
        phone: [
          {
            required: true,
            validator: getPhoneValidator,
            trigger: "change",
          },
        ],
        addressCodeList: [
          { required: true, message: "省市区不能为空", trigger: "change" },
        ],
        status: [
          { required: true, message: "状态不能为空", trigger: "change" },
        ],
        idCard: [
          { required: false, validator: getIDCardValidator, trigger: "change" },
        ],
      },

      // 表单数据
      formItemList: formItemList,

      // 分页
      pagingOptions: {
        limit: 10,
        page: 1,
        total: 0,
      },
    }
  },

  mounted() {
    this.getData()
  },

  methods: {
    // 查询
    _query(form) {
      this.getData()
    },

    // 添加
    _add() {
      this.visible = true
      this.dialogTitle = "添加用户"
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$refs.form.$refs.form.resetFields()
        })
      })
    },

    // 导出
    _export(form) {
      import("../../vendor/Export2Excel").then((excel) => {
        const tHeader = getExcelLabelOrProp("编号", "label", this.columns)
        const filterVal = getExcelLabelOrProp("serial", "prop", this.columns)
        const list =
          this.selectChangeTableData.length > 0
            ? this.selectChangeTableData
            : this.tableData
        const data = this.formatJson(filterVal, list)
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: "用户管理",
          bookType: "xlsx",
        })
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]))
    },

    // 多选事件
    _handleSelectionChange(val) {
      this.selectChangeTableData = val
    },

    // 修改
    _edit(row) {
      console.log(2222, row)
      this.dialogTitle = "修改用户"
      this.visible = true
      let sex = null
      switch (row.sex) {
        case "男":
          sex = 1
          break
        case "女":
          sex = 2
          break
        case "未知":
          sex = 3
          break
      }
      this.form = JSON.parse(JSON.stringify(row))
      this.form.sex = sex
    },

    // 删除
    async _del(row) {
      this.loading = true
      const res = await delUser(row.id)
      if (res.code === 200) {
        this.loading = false
        this.$message({ message: "删除成功", type: "success" })
        this.getData()
      }
    },

    // 每页多少条事件
    _sizeChange(val) {
      this.pagingOptions.limit = val
      this.getData()
    },

    // 当前页事件
    _currentChange(val) {
      this.pagingOptions.page = val
      this.getData()
    },

    // 取消
    _cancel(dialogForm) {
      this.closeForm(dialogForm)
    },

    _cascaderChange(args) {
      console.log(args)
      this.form.address = args && args[0]
    },

    // 确定
    _confirm(dialogForm) {
      console.log(this.form)
      this.$refs.form.$refs[dialogForm].validate(async (valid) => {
        if (valid) {
          if (this.dialogTitle === "添加用户") {
            try {
              const res = await createUser(this.form)
              if (res.code === 200) {
                this.visible = false
                this.$message({ message: "添加用户成功", type: "success" })
                this.getData()
              }
            } catch (err) {
              this.visible = false
              return this.$message({ message: "添加用户失败", type: "error" })
            }
          }
          if (this.dialogTitle === "修改用户") {
            try {
              const res = await updateUser(this.form.id, this.form)
              if (res.code === 200) {
                this.visible = false
                this.$message({ message: "修改用户成功", type: "success" })
                this.getData()
              }
            } catch (err) {
              this.visible = false
              return this.$message({ message: "修改用户失败", type: "error" })
            }
          }
        } else {
          console.log("error submit!!")
          return false
        }
      })
    },

    // 表单关闭按钮
    _Close() {
      this.closeForm()
    },

    // 关闭表单
    closeForm() {
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$refs.form.$refs.form.resetFields()
        })
      })
      this.visible = false // 关闭弹框
    },

    // 获取用户管理数据
    async getData() {
      const { limit, page } = this.pagingOptions
      this.loading = true
      try {
        const res = await getUserList(limit, page, {
          phone: this.queryForm.phone,
          realName: this.queryForm.name,
        })
        // console.log(11111, res)
        this.loading = false
        this.tableData = res.data && res.data.list
        this.tableData.forEach((_) => {
          _.sex = getSex(_.sex) // 获取性别
        })
        this.pagingOptions.total = res.data.total
      } catch (error) {
        // console.log(22222, error)
        this.loading = false
        this.tableData = []
        this.pagingOptions.total = 0
        this.$message({
          showClose: true,
          message: "获取数据失败",
          type: "error",
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.user-manage {
  height: 100%;
  padding: 0 16px 0 16px;
  background-color: #fff;
}
</style>